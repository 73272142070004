import React, {useState} from 'react';
import './styles/SearchBar.css';
import SearchIcon from '@mui/icons-material/Search';
import Cards from "./Cards";


function SearchBar({data, divider, finalReportIds}) {
    const [searchField, setSearchField] = useState("");

    const handleChange = (event) => {
        setSearchField(event.target.value);
    }

    const filteredReports = data.filter(
        report => {
            return (
                report.name.toLowerCase().includes(searchField.toLowerCase()) || report.keyUsers.toLowerCase().includes(searchField.toLowerCase())
            );
        }
    );


    return (
        <>
            <div className="search">
                <div className="searchInputs">
                    <input type="text" className="input" placeholder="Search report..." onChange={handleChange}/>
                    <div className="searchIcon">
                        <SearchIcon color="disabled"/>
                    </div>
                </div>
            </div>
            <Cards data={filteredReports} divider={divider} finalReportIds={finalReportIds}/>
        </>
    )
}

export default SearchBar;