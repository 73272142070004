import {LogLevel} from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: '32c676c6-0b98-45ad-86ed-4299bd362b96',
        redirectUri: '/',
        authority: 'https://login.microsoftonline.com/eb272a1e-e767-4041-9363-f8736579f898',
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: ["openid", "profile"]
};

export const scopeBase = ["https://analysis.windows.net/powerbi/api/Report.Read.All", "user.read"];

export const emailData = {
    emailTo: "Data&AI-Data-Sci@trackman.com",
    emailSubject: "General Information!"
}