import React, {useEffect, useState} from 'react';
import '../App.css';
import {golfData} from "../reportConfigs/golfReports";
import {baseballData} from "../reportConfigs/baseballReports";
import {footballData} from "../reportConfigs/footballReports";
import SearchBar from "./SearchBar";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import {useMsal} from "@azure/msal-react";
import {scopeBase} from "../Config";
import {default as axios} from "axios";
import {useLocation} from "react-router-dom";

const dataMap = {
    golf: golfData,
    baseball: baseballData,
    football: footballData
};

export default function TypeSport() {
    const [accessToken, setAccessToken] = useState(null)
    const [userApps, setUserApps] = useState(() => JSON.parse(localStorage.getItem('userApps')) || []);
    const [userGroups, setUserGroups] = useState(() => JSON.parse(localStorage.getItem('userGroups')) || []);
    const [appReportIds, setAppReportIds] = useState(() => JSON.parse(localStorage.getItem('userAppsReports')) || []);
    const [groupReportIds, setGroupReportIds] = useState(() => JSON.parse(localStorage.getItem('userGroupsReports')) || []);
    const [userReportIds, setUserReportIds] = useState(() => JSON.parse(localStorage.getItem('userReports')) || []);
    const {instance, inProgress, accounts} = useMsal();
    const [loadingTokenRetries, setLoadingTokenRetries] = React.useState(5);

    const params = useLocation();
    const pathParams = params?.pathname.split('/');

    // URL Path Params
    const type = pathParams[1];
    const sport = pathParams[2];

    const retrieveAppReports = async () => {
        if (userApps && accessToken) {
            const promises = userApps.map(value => {
                let options = {
                    method: 'GET',
                    url: `https://api.powerbi.com/v1.0/myorg/apps/${value}/reports`,
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                };

                return axios.request(options).then(function (response) {
                    return response.data.value.map(item => item.id);
                }).catch(function (error) {
                    console.log('Error fetching app reports', error);
                    return []; // Return an empty array if there's an error
                });
            });

            try {
                const results = await Promise.all(promises);
                const finalReportsIds = results.flat(); // Combine all arrays into one
                localStorage.setItem("userAppsReports", JSON.stringify(finalReportsIds));
                setAppReportIds(finalReportsIds);
            } catch (error) {
                console.error('Error fetching app reports:', error);
            }
        }
    }

    const retrieveApps = (accessToken) => {
        if (accessToken) {
            let options = {
                method: 'GET',
                url: 'https://api.powerbi.com/v1.0/myorg/apps',
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            };

            let tempAppIds = [];
            axios.request(options).then(function (response) {
                tempAppIds = response.data.value.map(item => item.id);
                setUserApps(tempAppIds);
                localStorage.setItem("userApps", JSON.stringify(tempAppIds));
            }).catch(function (error) {
                tempAppIds = JSON.parse(localStorage.getItem("userApps"));
            });

        }
    }

    const retrieveGroupReports = async () => {
        if (userGroups && accessToken) {
            const promises = userGroups.map(value => {
                let options = {
                    method: 'GET',
                    url: `https://api.powerbi.com/v1.0/myorg/groups/${value}/reports`,
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                };

                return axios.request(options).then(function (response) {
                    return response.data.value.map(item => item.id);
                }).catch(function (error) {
                    console.log('Error fetching group reports', error);
                    return []; // Return an empty array if there's an error
                });
            });

            try {
                const results = await Promise.all(promises);
                const finalReportsIds = results.flat(); // Combine all arrays into one
                localStorage.setItem("userGroupsReports", JSON.stringify(finalReportsIds));
                setGroupReportIds(finalReportsIds);
            } catch (error) {
                console.error('Error fetching group reports:', error);
            }
        }
    }

    const retrieveGroups = (accessToken) => {
        if (accessToken) {
            let options = {
                method: 'GET',
                url: 'https://api.powerbi.com/v1.0/myorg/groups',
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            };

            let tempGroupIds = [];
            axios.request(options).then(function (response) {
                tempGroupIds = response.data.value.map(item => item.id);
                setUserGroups(tempGroupIds);
                localStorage.setItem("userGroups", JSON.stringify(tempGroupIds));
            }).catch(function (error) {
                tempGroupIds = JSON.parse(localStorage.getItem("userGroups"));
            });

        }
    }

    const retrieveReports = (accessToken) => {
        if (accessToken) {
            let options = {
                method: 'GET',
                url: 'https://api.powerbi.com/v1.0/myorg/reports',
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            };

            let tempReportIds = [];
            axios.request(options).then(function (response) {
                tempReportIds = response.data.value.map(item => item.id);
                setUserReportIds(tempReportIds);
                localStorage.setItem("userReports", JSON.stringify(tempReportIds));
            }).catch(function (error) {
                tempReportIds = JSON.parse(localStorage.getItem("userReports"));
            });

        }
    }

    const acquireToken = () => {

        if (!!accessToken || inProgress !== InteractionStatus.None) {
            // if we have an access token or the useMsal hook is in progress, then we return
            return;
        }

        // loading token
        const accessTokenRequest = {
            scopes: scopeBase,
            account: accounts[0],
        };

        if (inProgress === InteractionStatus.None) {
            instance.acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // set token in state
                    setAccessToken(accessTokenResponse.accessToken);
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        let tempLoadingTokenRetries = loadingTokenRetries - 1;
                        setLoadingTokenRetries(tempLoadingTokenRetries);
                        if (tempLoadingTokenRetries > 0) {
                            acquireToken();
                        }
                    }
                    console.log(error.scopes);
                });
        }

    }

    useEffect(() => {
        acquireToken();
    }, [accessToken])

    useEffect(() => {
        if (accessToken) {
            retrieveApps(accessToken);
            retrieveGroups(accessToken);
        }
    }, [accessToken]);

    useEffect(() => {
        if (accessToken) {
            retrieveAppReports();
            retrieveGroupReports();
            retrieveReports(accessToken);
        }
    }, [userApps, userGroups]);

    const reports = dataMap[sport].filter(item => {return item.type === type;});

    return (
        <>
            {appReportIds.length + groupReportIds.length + userReportIds.length > 0 && (
                <div className='content'>
                    <SearchBar data={reports} divider={sport.toUpperCase()}
                               finalReportIds={[...appReportIds, ...groupReportIds, ...userReportIds]}/>
                </div>
            )}
        </>
    );

}
