import React from 'react';
import Navbar from './components/Navbar/Navbar';
import './App.css';
import {Helmet} from 'react-helmet';
import Home from './components/Home';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import PowerBiApp from "./components/PowerBiApp";
import TypeSport from "./components/TypeSport";

function App({msalInstance}) {
    return (
        <>
            <Helmet>
                <link href='https://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css'/>
            </Helmet>
            <MsalProvider instance={msalInstance}>
                <Router>
                    <AuthenticatedTemplate>
                        <Navbar/>
                        <Switch>
                            <Route path='/:type/:sport/:appId/:w_id/:r_id' component={PowerBiApp}/>
                            <Route path='/:type/:sport/:appId/:w_id' component={PowerBiApp}/>
                            <Route path='/:type/:sport/:appId' component={PowerBiApp}/>
                            <Route path='/:type/:sport' component={TypeSport}/>
                            <Route path='/' exact component={Home}/>
                            <Route path='/*' exact component={Home}/>
                        </Switch>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Navbar/>
                        <Switch>
                            <Route path='/*' exact component={Home}/>
                        </Switch>
                    </UnauthenticatedTemplate>
                </Router>
            </MsalProvider>
        </>
    );
}

export default App;
