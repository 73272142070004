import React, {useEffect, useState} from 'react';
import './styles/PowerBiApp.css';
import {default as axios} from "axios";
import {models} from "powerbi-client";
import {PowerBIEmbed} from "powerbi-client-react";
import {useMsal} from "@azure/msal-react";
import {scopeBase} from "../Config";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import {useHistory, useLocation} from 'react-router-dom';
import {golfData} from "../reportConfigs/golfReports";
import {baseballData} from "../reportConfigs/baseballReports";
import {footballData} from "../reportConfigs/footballReports";

// Define an object mapping variable values to imported data
const dataMap = {
    golf: golfData,
    baseball: baseballData,
    football: footballData
};



export default function PowerBiApp(props) {
    const [sideMenuItems, setSideMenuItems] = useState([])
    const [itemToSend, setItemToSend] = useState(null)
    const [accessToken, setAccessToken] = useState(null)
    const [appName, setAppName] = useState(null)
    const [appType, setAppType] = useState(null)
    const [loading, setLoading] = useState(false);

    const {instance, inProgress, accounts} = useMsal();
    const history = useHistory();
    const params = useLocation();
    const pathParams = params?.pathname.split('/');

    // URL Path Params
    const type = pathParams[1];
    const sport = pathParams[2];
    const appId = pathParams[3] || props.location?.data?.appId;
    const w_id = pathParams[4];
    const r_id = pathParams[5];


    //=== login logic ===
    const [loadingToken, setLoadingToken] = React.useState(false);
    const [loadingTokenRetries, setLoadingTokenRetries] = React.useState(5);
    const [loadingTokenError, setLoadingTokenError] = React.useState(false);

    const acquireToken = () => {

        if (!!accessToken || inProgress !== InteractionStatus.None) {
            // if we have an access token or the useMsal hook is in progress, then we return
            return;
        }

        // loading token
        setLoadingToken(true);
        const accessTokenRequest = {
            scopes: scopeBase,
            account: accounts[0],
        };

        if (inProgress === InteractionStatus.None) {
            instance.acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // set token in state
                    setAccessToken(accessTokenResponse.accessToken);
                    // loading token false, this means we did get a correct token
                    setLoadingToken(false);
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {

                        // if we do get an error, then for some reason, we still need to re-get the token, and retry
                        // this will get called every time we fail to load
                        let tempLoadingTokenRetries = loadingTokenRetries - 1;
                        setLoadingTokenRetries(tempLoadingTokenRetries); // we have a counter that limits the amount of times a recall can be made
                        if (tempLoadingTokenRetries > 0) {
                            acquireToken();
                        } else if (tempLoadingTokenRetries <= 0) {
                            setLoadingTokenError(true);
                        }
                    }
                    console.log(error.scopes);
                });
        }

    }

    useEffect(() => {
        // on first render, this will try to get token, or everytime the in-progress changes
        acquireToken();
    }, [inProgress])

    //=== end of login logic ===

    const handleClickItem = (item, accessToken) => {
        setLoading(true);

        history.push((item.workspaceId) ? `/${item.type}/${item.sport}/${item.appId}/${item.workspaceId}/${item.reportId}`: `/${item.type}/${item.sport}/${item.appId}/${item.applicationId}/${item.reportId}`);

        let options = {
            method: 'GET',
            url: (item.workspaceId) ? 'https://api.powerbi.com/v1.0/myorg/groups/' + item.workspaceId + '/reports/' + item.reportId : 'https://api.powerbi.com/v1.0/myorg/apps/' + item.applicationId + '/reports/' + item.reportId,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        };

        axios.request(options).then(function (response) {
            item.embedURL = response.data.embedUrl;
            setItemToSend(item);
            setLoading(false);
        }).catch(function (error) {
            item.embedURL = null;
            setItemToSend(item);
            setLoading(false);
        });

    }

    React.useEffect(() => {
        // Stores/gets report/app info in local storage or from URL path parameters
        let tempSideMenuItems = null;
        let tempAppName = null;
        let tempAppType = type;
        let data = null;
        if (type && sport && appId && r_id && w_id) {
            data = dataMap[sport].find(item => (item.appId === appId && item.reports.some(report => (report.reportId === r_id) && (report.workspaceId === w_id || report.applicationId === w_id))));

            if (data) {
                // It means the report exists - take the required data
                tempSideMenuItems = data.length > 1 ? data[0].reports : data.reports // Check the length of data and take the first is greater than 1 (in case the same id is assigned to more than one object)
                tempAppName = data.length > 1 ? data[0].name : data.name

                // Attach sport, appId and type to each report
                tempSideMenuItems = tempSideMenuItems.map(item => ({...item, appId: appId, sport: sport, type: type}));
                setSideMenuItems(tempSideMenuItems);
                setAppName(tempAppName);
                setAppType(type);
            } else {
                // Redirect to cards page
                history.push(`/${type}/${sport}`)
                return
            }

        }
        else {
            if (!!props.location?.reports) {
                tempSideMenuItems = props.location?.reports
                tempSideMenuItems = tempSideMenuItems.map(item => ({...item, appId: appId, sport: sport, type: type}));
                localStorage.setItem("powerBI_appReports", JSON.stringify(tempSideMenuItems));
            } else {
                tempSideMenuItems = JSON.parse(localStorage.getItem("powerBI_appReports") || "[]");
            }

            setSideMenuItems(tempSideMenuItems);

            if (!!props.location?.data?.name) {
                tempAppName = props.location?.data.name
                tempAppType = props.location?.data.type
                localStorage.setItem("powerBI_appName", JSON.stringify(tempAppName));
                localStorage.setItem("powerBI_appType", JSON.stringify(tempAppType));
            } else {
                tempAppName = JSON.parse(localStorage.getItem("powerBI_appName"));
                tempAppType = JSON.parse(localStorage.getItem("powerBI_appType"));
            }
            setAppName(tempAppName);
            setAppType(tempAppType);
        }

        // Find the matching report or take the first one from the app
        let matchingReport = null;
        if (r_id && w_id) {
            matchingReport = tempSideMenuItems.find(report => report.reportId === r_id && (report.workspaceId === w_id || report.applicationId === w_id));
        }
        let item = matchingReport ? matchingReport : (tempSideMenuItems && tempSideMenuItems.length > 0 ? tempSideMenuItems[0] : null);
        if (item) {
            handleClickItem(item, accessToken)
        }
    }, [w_id, r_id, accessToken])


    let getNewAccessToken = async function () {
        const accessTokenRequest = {
            scopes: scopeBase,
            account: accounts[0],
        };
        instance.acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    setAccessToken(accessTokenResponse.accessToken)
                    return accessTokenResponse.accessToken;
                })
                .catch((error) => {
                    console.log(error.scopes);
                });
    };


    return (
        <div className='container'>

            {
                loadingToken ?
                    <>
                        {/*Loading token, please wait*/}
                    </>
                    :
                    <>
                        {
                            loadingTokenError ?
                                <>
                                    {/*we didnt load token, and we have an error*/}
                                </>
                                :
                                <>
                                    <div className='split left'>
                                        <h3 className='side-title centered'>{appName}</h3>
                                        <div className='side-items'>
                                            {
                                                sideMenuItems?.map((item, key) => {
                                                    return (
                                                        <li className={`side-links ${itemToSend?.reportId === item.reportId && itemToSend?.workspaceId === item.workspaceId && 'active'}`}
                                                            onClick={() => handleClickItem(item, accessToken)}
                                                        >

                                                <span
                                                    className={'report-item'}
                                                >
                                                    {item.name}
                                                </span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    {
                                        !loading &&
                                        <div className='right'>
                                            {
                                                itemToSend !== null ?
                                                    <PowerBIEmbed
                                                        key={accessToken}
                                                        embedConfig={{
                                                            type: 'report',
                                                            id: itemToSend.reportId,
                                                            embedUrl: itemToSend.embedURL,
                                                            accessToken: accessToken,
                                                            tokenType: models.TokenType.Aad,
                                                            eventHooks: {
                                                                accessTokenProvider: getNewAccessToken
                                                            },
                                                            settings: {
                                                                panes: {
                                                                    filters: {
                                                                        expanded: (itemToSend?.visible ?? false),
                                                                        visible: (itemToSend?.visible ?? false)
                                                                    }
                                                                },
                                                                background: models.BackgroundType.Transparent,
                                                                layoutType: models.LayoutType.Custom,
                                                                customLayout: {
                                                                    displayOption: models.DisplayOption.FitToPage
                                                                }
                                                            }
                                                        }}
                                                        eventHandlers={
                                                            new Map([
                                                                ['loaded', function () {
                                                                    console.log('Report loaded');
                                                                }],
                                                                ['rendered', function () {
                                                                    console.log('Report rendered');
                                                                }],
                                                                ['error', function (event) {
                                                                    console.log(event.detail);
                                                                }],
                                                            ])
                                                        }
                                                        cssClassName={(sport === 'golf' && appType === 'customer') ? 'Embed-container-split powerbi-container' : 'Embed-container-split'}
                                                        getEmbeddedComponent={(embeddedReport) => {
                                                            window.report = embeddedReport
                                                        }}
                                                    />
                                                    :
                                                    <div className='report-container'>
                                                        {/*<h3>Please select a report from the left panel</h3>*/}
                                                    </div>
                                            }
                                        </div>
                                    }

                                </>
                        }
                    </>
            }
        </div>
    );

}
