import React from 'react';
import {Link} from 'react-router-dom';


function CardItem(props) {
    const accessReports = props?.data?.reports.some(report => props?.finalReportIds.includes(report.reportId));

    return (
        <>
            <li className='cards__item'>
                <Link
                    className={`${accessReports ? 'cards__item__link' : 'cards__item__link__exclude'}`}
                    to={{
                        data: props?.data,
                        pathname: `/${props?.data?.type}/${props?.data?.sport}/${props?.data?.appId}/${props?.data?.reports[0]?.applicationId || props?.data?.reports[0]?.workspaceId}/${props?.data?.reports[0]?.reportId}`,
                        params: props?.data?.params,
                        accessToken: props?.accessToken,
                        reports: props?.data?.category === 'report' ? null : props?.data?.reports
                    }}
                    title={!accessReports ? "You don't have permission to view reports for this app but you can request access! " : ""}
                >
                    <div className='cards__item__info'>
                        <h4 className='cards__item__title'>{props?.data?.name}</h4>
                        <p className='cards__item__subtitle'>Key Users</p>
                        <p className='cards__item__text'>{props?.data?.keyUsers}</p>
                        { // The access info is removed, as we are not using it any more 20240524 (JDY), not sure if we need to delete code from others places?
                            // <p className='cards__item__text'>Access: {props?.data?.access}</p>
                        }
                        <p className='cards__item__subtitle'>Key Use Cases</p>
                        <p className='cards__item__text' dangerouslySetInnerHTML={{__html: props?.data?.keyUseCases}}/>
                        <p className='cards__item__subtitle'>Data Summary</p>
                        <p className='cards__item__text' dangerouslySetInnerHTML={{__html: props?.data?.dataSummary}}/>
                    </div>
                </Link>
            </li>
        </>
    );
}

export default CardItem;
