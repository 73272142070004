import React from 'react';
import * as RiIcons from 'react-icons/ri';


export const SidebarData = [
    {
        title: 'Internal Reports',
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: 'Golf',
                path: '/internal/golf',
            },
            {
                title: 'Baseball',
                path: '/internal/baseball',
            },
            {
                title: 'Football',
                path: '/internal/football',
            }
        ]
    },
    {
        title: 'Customer Reports',
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: 'Golf',
                path: '/customer/golf',
            },
            {
                title: 'Baseball',
                path: '/customer/baseball',
            },
            {
                title: 'Football',
                path: '/customer/football',
            }
        ]
    }
];