import React, {useState} from 'react';
import './styles/Cards.css';
import CardItem from './CardItem';
import {Divider} from "@material-ui/core";
import {useMsal} from "@azure/msal-react";
import {scopeBase} from "../Config";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";


function Cards(props) {
    const {instance, inProgress, accounts} = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const accessTokenRequest = {
        scopes: scopeBase,
        account: accounts[0],
    };

    React.useEffect(() => {
            if (!accessToken && inProgress === InteractionStatus.None) {
                instance.acquireTokenSilent(accessTokenRequest)
                    .then((accessTokenResponse) => {
                        let accessToken = accessTokenResponse.accessToken;
                        setAccessToken(accessToken)
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect(accessTokenRequest);
                        }
                        console.log(error.scopes);
                    });
            }
        }, []
    )

    return (
        <>
            {
                (props.data.length !== 0) ?
                    <>
                        <div className='cards'>
                            <div className='cards__container'>
                                <Divider className='divider'>{props.divider && props.divider.toUpperCase()}</Divider>
                                <div className='cards__wrapper'>
                                    {props.data.map((data, key) => {
                                        return (
                                            <CardItem
                                                key={key}
                                                data={data}
                                                accessToken={accessToken}
                                                finalReportIds={props.finalReportIds}
                                            />
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className='empty_cards_page'>
                        Currently no reports available for this section!
                    </div>

            }

        </>

    );
}

export default Cards;
