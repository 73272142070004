export const InternalMenuItems = [
    {
        title: 'Golf',
        path: '/internal/golf',
        cName: 'dropdown-link',
        type: 'internal'
    },
    {
        title: 'Baseball',
        path: '/internal/baseball',
        cName: 'dropdown-link',
        type: 'internal'
    },
    {
        title: 'Football',
        path: '/internal/football',
        cName: 'dropdown-link',
        type: 'internal'
    }
];

export const CustomerMenuItems = [
    {
        title: 'Golf',
        path: '/customer/golf',
        cName: 'dropdown-link',
        type: 'customer'
    },
    {
        title: 'Baseball',
        path: '/customer/baseball',
        cName: 'dropdown-link',
        type: 'customer'
    },
    {
        title: 'Football',
        path: '/customer/football',
        cName: 'dropdown-link',
        type: 'customer'
    }
];
