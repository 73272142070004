import React, {useState} from 'react';
import {InternalMenuItems, CustomerMenuItems} from './MenuItems';
import '../styles/Dropdown.css';
import {NavLink} from 'react-router-dom';

function Dropdown(props) {
    const [click, setClick] = useState(false);
    const [categoryType, setCategoryType] = useState(props.type)

    const setClickAndCategoryType = () => {
        setClick(false);

        let tempCategoryType = null;
        if (!!props.type) {
            tempCategoryType = props.type
            localStorage.setItem("powerBI_categoryType", tempCategoryType);
        } else {
            tempCategoryType = localStorage.getItem("powerBI_categoryType");
        }
        setCategoryType(tempCategoryType);
    }

    let menuItems = (categoryType === 'internal' ? InternalMenuItems : CustomerMenuItems)

    return (
        <>
            <ul
                className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
                onClick={() => setClick(!click)}
            >
                {menuItems.map((item, index) => {
                    return (
                        <li key={index}>
                            <NavLink
                                className={item.cName}
                                to={{
                                    pathname: item.path,
                                    userProps: {type: item.type}
                                }}
                                onClick={setClickAndCategoryType}
                            >
                                {item.title}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

export default Dropdown;