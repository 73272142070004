const baseballData = [

    //Workspace: Baseball Data Delivery
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on report type)
        "name": "v3 Data Delivery",
        "keyUsers": "Data Desk, Tier 3",
        "access": "All Staff",
        "keyUseCases": "<ul><li>Data Delivery Overview</li><li>Delivery Timing</li><li>Historical Data Delivery</li></ul>",
        "dataSummary": "<ul><li>History: vary from rolling 2 weeks to 13 month</li><li>Updates 8x/Day: Automatic</li></ul>",
        "reports": [
            {
                reportId: 'd1be80e9-7e2e-40c6-a7ed-d303ce27772c',
                applicationId: '27536a04-f53a-4fe5-987c-6c78d3fe3268', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Delivery Overview",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '7a8b1d67-cc50-49a9-9e23-66f5aed2167e',
                applicationId: '27536a04-f53a-4fe5-987c-6c78d3fe3268', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Delivery Tracker",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            /*{
                reportId: 'af4763ea-013a-4418-bf51-9e6a9e933771',
                applicationId: '27536a04-f53a-4fe5-987c-6c78d3fe3268', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Delivery Timing - Stadium",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '6d485db9-029d-46af-bd16-aed3a1984843',
                applicationId: '27536a04-f53a-4fe5-987c-6c78d3fe3268', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Delivery Timing - Backfield",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '55a8f6d1-ad60-4e9a-bfde-1cc171651d07',
                applicationId: '27536a04-f53a-4fe5-987c-6c78d3fe3268', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Auto-Verify Performance",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },*/
            {
                reportId: '60d56327-1f8c-45b8-a825-22d3ee4712f1',
                applicationId: '27536a04-f53a-4fe5-987c-6c78d3fe3268', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Historical Data Delivery",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'customer', //(internal/customer depending on report type)
        "name": "Stadium Customer Reports 1.1",
        "keyUsers": "v3 Customers",
        "access": "All Staff",
        "keyUseCases": "<ul><li>Game Data Review</li><li>Player Tracking</li><li>Custom Report</li><li>Team Dashboard</li></ul>",
        "dataSummary": "<ul><li>History: 13 months to 3 years Rolling</li><li>Updates Hourly: Automatic</li></ul>",
        "reports": [
            {
                reportId: 'cdf762fe-aebc-42de-be46-dcda298aac26',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "1.1 Player Report - V3",
                visible: true //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'e42f2e89-6517-4e94-a2b8-d88619edee65',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "1.1 Custom Player Report - Stadium",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '787d6055-89c4-4942-894b-894bf8ec97ea',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "1.1 Team Dashboard - Stadium",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '24a10390-0f7b-416b-9efd-4af4bcb0e0fc',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "1.1 Player Tracking - Stadium",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '08a5e2dc-1631-43d1-b183-c9ebe4fdf45f',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "1.1 Strike Zone Report - Stadium",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on app type)
        "name": "v3 Data Quality", //(app title)
        "keyUsers": "Support",
        "access": "All Staff",
        "keyUseCases": "<ul><li>Monthly Numbers</li><li>3D Spin Pickup Rate</li><li>Fields Performance</li><li>Session Trends</li><li>DQ SLA Report</li></ul>",
        "dataSummary": "<ul><li>History: 13 Months</li><li>Updates Daily: Automatic</li></ul>",
        "reports": [
            {
                reportId: '11d441aa-3b3c-49b9-8988-e97ba05c1b37',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "3D Spin Pickup Rate",
                visible: false
            },
            {
                reportId: '6eea2963-f2f2-4cc3-a21f-a7ff857b27c0',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "Performance to Spec",
                visible: false
            },
            {
                reportId: 'f640f64d-3899-4eec-8bb9-f50a6b7891e6',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "Fields Performance",
                visible: false
            },
            {
                reportId: '2a18558d-2c1c-4fcd-98ff-e8ac8409ebb9',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "Worst Performers",
                visible: false
            },
            {
                reportId: '2dcb66a7-b33a-4359-9359-15a74f6e25c0',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "Session Trends",
                visible: false
            },
            {
                reportId: 'd83cd65d-42d6-42a7-85fe-ee5d8b8b8710',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "Rolling Offsets",
                visible: false
            },
            {
                reportId: 'b389968f-4be8-4b2d-bdee-05ceb055edaa',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "Breaks & Confidence",
                visible: false
            },
            {
                reportId: '33e1c4f0-c962-4037-8e96-e4f312b21083',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "UmpireAssist Games Summary",
                visible: false
            },
            {
                reportId: '1b5bbecd-850e-4876-84fa-e49e16758c39',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "DQ SLA report",
                visible: false
            },
            {
                reportId: 'd7cdeb96-8706-4743-8f1d-a3be5373a8f4',
                applicationId: 'f465903f-f0e3-45f4-b5fa-b5b03213406d',
                name: "V3 and B1 Monthly numbers",
                visible: false
            },
        ]
    },
    //Workspace: Baseball Player Tracking
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on report type)
        "name": "Player Tracking Reports",
        "keyUsers": "Baseball POs, Data Desk, Tier 3",
        "access": "All Staff",
        "keyUseCases": "<ul><li>Player Tracking Monitoring</li><li>Player Positions Monitoring</li></ul>",
        "dataSummary": "<ul><li>History: 13 months Rolling</li><li>Updates 8x/Day: Automatic</li></ul>",
        "reports": [
            {
                reportId: 'b1797323-6271-4c8c-a203-420f913fbba0',
                applicationId: '094ec093-1690-4f84-9ba0-693fd545fd13', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "PT Monitoring",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            /*{
                reportId: '5b44ee20-6115-422d-8f4d-6853489f47fb',
                applicationId: '094ec093-1690-4f84-9ba0-693fd545fd13', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "PT Data Quality",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },*/
            {
                reportId: '95d8c866-c8d3-4dc9-a94d-60ac34bc9af6',
                applicationId: '094ec093-1690-4f84-9ba0-693fd545fd13', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Player Positions- Session Distribution",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '9a501313-056c-4af7-b982-166c2de26502',
                applicationId: '094ec093-1690-4f84-9ba0-693fd545fd13', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Player Positions- Per Field",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    
    //Workspace: Baseball Team Customer
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'customer', //(internal/customer depending on report type)
        "name": "Portable Customer Reports 1.1",
        "keyUsers": "B1 Customers",
        "access": "All Staff",
        "keyUseCases": "<ul><li>1.1 Practice Report</li></ul>",
        "dataSummary": "<ul><li>Updates every 15min: Automatic</li></ul>",
        "reports": [
            {
                reportId: '96bd5b58-3abc-4fc6-a129-8dee2f0da679',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "1.1 Player Report - B1",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },


    
    //Workspace: Baseball Team Customer
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'customer', //(internal/customer depending on report type)
        "name": "Umpire Reports",
        "keyUsers": "v3 Customers, Data Desk",
        "access": "All Staff",
        "keyUseCases": "<ul><li>Umpire Report</li><li>Admin Report</li></ul>",
        "dataSummary": "<ul><li>History: 13 months Rolling</li><li>Updates Hourly: Automatic</li></ul>",
        "reports": [
            {
                reportId: 'aeb3bf75-d3aa-498e-8774-0f52c3008cbd',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "1.1 Umpire - Admin Report",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'ee167094-8a28-4937-8927-368cbf6e0450',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "1.1 Umpire - Individual Umpire Report",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'ab9cbbdb-36e5-4a05-846d-81b5ce83ecf5',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "1.1 Umpire - Owning Organization Report",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },
    //Workspace: Baseball Team Customer
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'customer', //(internal/customer depending on report type)
        "name": "Stadium Customer Reports",
        "keyUsers": "v3 Customers",
        "access": "All Staff",
        "keyUseCases": "<ul><li>Game Data Review</li><li>Player Development</li><li>Scouting, Recruiting</li></ul>",
        "dataSummary": "<ul><li>History: 13 months to 3 years Rolling</li><li>Updates Hourly: Automatic</li></ul>",
        "reports": [
            {
                reportId: 'f225254d-5b03-434a-ae95-9e63b71bb630',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium - Team Exclusive",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'a1b576ab-2ab9-4b5b-93bc-5ce57d6a38ce',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium - NCAA Networked Games",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '0a74412d-675f-47e6-aa22-f643509ec29a',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium Player Handout - Pitching",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'b41a97b8-1b5a-4de1-9ff5-300b019df307',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium Player Handout - Hitting",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '618a5dcf-1221-406e-abff-42e9efebce92',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium - American Association Networked Games",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'dfa37ca6-b01e-4006-8b3d-69c6058c233d',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium - Atlantic League Networked Games",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '5b87438d-2086-4953-b37f-2871b119e262',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium - LMP Networked Games",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'f051b79a-71a1-45d1-8d95-e661cb89bd58',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium - Mexican League Networked Games",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '99163dbc-c726-442f-ab7d-9b047af920f7',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium - PL Networked Games",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '5dafbc87-cea3-4fb2-8268-eb0f8f06274b',
                applicationId: '0afd6fd7-3f79-4130-be7b-af211372f802', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Stadium - U25 LMB Networked Games",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    //Workspace: Baseball Portable Customer
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'customer', //(internal/customer depending on report type)
        "name": "Portable Customer Reports",
        "keyUsers": "B1 Customers",
        "access": "All Staff",
        "keyUseCases": "<ul><li>Portable Pitching Report</li><li>Portable Hitting Report</li><li>Portable LiveAB Reports</li></ul>",
        "dataSummary": "<ul><li>Updates every 15min: Automatic</li></ul>",
        "reports": [
            {
                reportId: '7847cf37-d12c-4c60-8236-218e929508a4',
                applicationId: 'c3c08f82-5e1c-4506-a29d-925779811e1c', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Portable Pitching",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'a7e2d7bf-ec91-4704-8635-72aaa4e38bf4',
                applicationId: 'c3c08f82-5e1c-4506-a29d-925779811e1c', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Portable Hitting",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '4f506c9c-6785-4e66-8572-e98e6269bad7',
                applicationId: 'c3c08f82-5e1c-4506-a29d-925779811e1c', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Portable LiveAB",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'e0d8d336-f6a6-4fe5-91bf-9e6c13775b05',
                applicationId: 'c3c08f82-5e1c-4506-a29d-925779811e1c', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Portable LiveAB Historical ",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

        //Workspace: Baseball Data Delivery Customer
        {
            "category": "app", //(report/app depending on single or multiple reports to show)
            "type": 'customer', //(internal/customer depending on report type)
            "name": "Baseball - Missing Games",
            "keyUsers": "Data Desk, Tier 3, v3 & B1 Customers",
            "access": "All Staff",
            "keyUseCases": "<ul><li>Scheduled Games & B1 Sessions Delivery Overview</li></ul>",
            "dataSummary": "<ul><li>History: 3 days Rolling</li><li>Updates 8x/Day: Automatic</li></ul>",
            "reports": [
                {
                    reportId: 'a74f898a-60cb-4fb8-8529-1b7069246fa3',
                    applicationId: '4a75a925-6392-491f-8e81-e3d043744a32', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                    name: "Missing Games -Customer Report",
                    visible: false //(true/false depending if you want the filter pane to be visible by default or no)
                },
                {
                    reportId: 'c1bd7c8c-1cfe-436a-9121-ac2306c58e3e',
                    applicationId: '4a75a925-6392-491f-8e81-e3d043744a32', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                    name: "Missing Games - History",
                    visible: false //(true/false depending if you want the filter pane to be visible by default or no)
                }
            ]
        },

    //Workspace: Baseball Portable Insights
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on report type)
        "name": "Portable Usage Insights",
        "keyUsers": "Baseball POs",
        "access": "All Staff",
        "keyUseCases": "<ul><li>B1 Usage Overview</li></ul>",
        "dataSummary": "<ul><li>History: 2 weeks rolling</li><li>Updates Daily: Automatic</li></ul>",
        "reports": [
            {
                reportId: 'bc8078fe-d67d-43b6-898e-79f29e38e3ae',
                applicationId: '6df9491f-b9a2-4e2b-98c1-667b9eec6010', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Portable Usage Insights",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    //Workspace: Baseball TSC
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on report type)
        "name": "TSC",
        "keyUsers": "Baseball POs",
        "access": "All Staff",
        "keyUseCases": "<ul><li>TSC Overview(ON/OFF)</li></ul>",
        "dataSummary": "<ul><li>History: Current Status</li><li>Updates Daily: Automatic</li></ul>",
        "reports": [
            {
                reportId: 'cdbcc5a8-38a4-4b34-8cdc-1b4978f815c2',
                applicationId: 'e8795615-416a-4882-9b02-f66fcaccbb0e', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "TSC",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    }

]


// ****************** UPDATE ALL THE OBJECTS WITH APP URL AND SPORT ****************** //
function createUrlFromName(name) {
    let url = name.toLowerCase();
    url = url.replace(/[^\w\s-]/g, '-');
    url = url.replace(/[\s-]+/g, '-');
    return url;
}

function updateAppIds(objects) {
    objects.forEach(obj => {
        const urlFromName = createUrlFromName(obj.name);
        const sport = 'baseball';
        obj.appId = urlFromName;
        obj.sport = sport;
        obj.reports = obj.reports.map(item => ({...item, appId: urlFromName, sport: sport}));
    });
}

updateAppIds(baseballData);
export {baseballData};