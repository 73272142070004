import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import {SidebarData} from './SideBarItems';
import {IconContext} from 'react-icons/lib';
import SubMenu from './SubMenu';
import {AuthenticatedTemplate, useMsal} from "@azure/msal-react";
import {emailData} from "../../Config";

const Nav = styled.div`
  background: #15171c;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
`;

const SidebarNav = styled.nav`
  background: #15171c;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({sidebar}) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10000;
  overflow: scroll;
  -ms-overflow-style: none;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarButton = styled.div`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #252831;
    border-left: 4px solid #ffffff;
    cursor: pointer;
  }
`;

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #252831;
    border-left: 4px solid #ffffff;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

function Sidebar() {
    const [sidebar, setSidebar] = useState(false);
    const {instance} = useMsal();
    const sidebarRef = useRef(null);
    const startX = useRef(null);

    const showSidebar = () => setSidebar(!sidebar);

    const handleTouchStart = (e) => {
        startX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        if (!startX.current) {
            return;
        }

        const currentX = e.touches[0].clientX;
        const diffX = startX.current - currentX;
        const sidebarWidth = sidebarRef.current.offsetWidth;

        if (diffX > 0 && diffX > sidebarWidth / 2) {
            setSidebar(false);
        }
    };

    const handleTouchEnd = () => {
        startX.current = null;
    };

    const handleEmailClick = () => {
        window.location.href = `mailto:${emailData.emailTo}?subject=${encodeURIComponent(emailData.emailSubject)}`;

    };

    useEffect(() => {
        const handleTouchOutside = (e) => {
            if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
                setSidebar(false);
            }
        };

        document.addEventListener('touchstart', handleTouchOutside);

        return () => {
            document.removeEventListener('touchstart', handleTouchOutside);
        };
    }, []);

    return (
        <>
            <AuthenticatedTemplate>
                <IconContext.Provider value={{color: '#fff'}}>
                    <Nav>
                        <NavIcon to='#'>
                            <FaIcons.FaBars onClick={showSidebar}/>
                        </NavIcon>
                    </Nav>
                    <SidebarNav
                        sidebar={sidebar}
                        ref={sidebarRef}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        <SidebarWrap>
                            <NavIcon to='#'>
                                <AiIcons.AiOutlineClose onClick={showSidebar}/>
                            </NavIcon>
                            <SidebarLink to='/'>
                                <SidebarLabel>Home</SidebarLabel>
                            </SidebarLink>
                            {SidebarData.map((item, index) => (
                                <SubMenu item={item} key={index}/>
                            ))}
                            <SidebarButton onClick={handleEmailClick}>
                                <SidebarLabel>Contact</SidebarLabel>
                            </SidebarButton>
                            <SidebarButton onClick={() => instance.logout()}>
                                <SidebarLabel>Logout</SidebarLabel>
                            </SidebarButton>
                        </SidebarWrap>
                    </SidebarNav>
                </IconContext.Provider>
            </AuthenticatedTemplate>
        </>
    );
}

export default Sidebar;
