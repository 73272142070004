import React, {useEffect, useState} from 'react';
import '../App.css';
import {useIsAuthenticated} from "@azure/msal-react";

export default function Home() {
    const isAuthenticated = useIsAuthenticated();
    const [firstName, setFirstName] = useState(() => {
        const storedFirstName = localStorage.getItem("firstName");
        return storedFirstName ? JSON.parse(storedFirstName) : "";
    });

    useEffect(() => {
        let tempFirstName = localStorage.getItem("firstName");
        setFirstName(tempFirstName);
    }, [isAuthenticated]);


    return (
        <>
            <div className='home'>
                {
                    (firstName) && (
                        <h3 className='home-title'>Welcome, {firstName?.replace(/^"(.*)"$/, '$1')}!</h3>
                    )}
            </div>
        </>

    );
}
