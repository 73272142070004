const footballData = [
    //Workspace: Football Usage Insights
    {
        "category": "app",
        "type": 'internal',
        "name": "Football Usage Insights",
        "keyUsers": "R&D, Business Development",
        "access": "Limited",
        "keyUseCases": "<ul><li>Insights into the usage of the football product</li></ul>",
        "dataSummary": "<ul><li>History: All available football data from Aug 2020</li><li>Updates Daily: Automatic</li></ul>",
        "reports": [
            {
                reportId: '61d73faf-5d4c-446b-8227-7555bd28f347',
                applicationId: 'c6eebeec-d00c-4edf-85b0-682316ec6883',
                name: "Football Usage Insights",
                visible: false
            }
        ]
    },

    //Workspace: American Football Usage Insights
    {
        "category": "app",
        "type": 'internal',
        "name": "American Football Usage Insights",
        "keyUsers": "R&D, Business Development",
        "access": "Limited",
        "keyUseCases": "<ul><li>Insights into the usage of the American football product</li></ul>",
        "dataSummary": "<ul><li>History: All available american football data</li><li>Updates Daily: Automatic</li></ul>",
        "reports": [
            {
                reportId: '8daddd21-98d5-4255-ba15-167f02dc3785',
                applicationId: 'a74fbb5e-6564-47e5-8d51-5ed56a38fe8e',
                name: "American Football Usage Insights",
                visible: false
            }
        ]
    }
]


// ****************** UPDATE ALL THE OBJECTS WITH APP URL AND SPORT ****************** //
function createUrlFromName(name) {
    let url = name.toLowerCase();
    url = url.replace(/[^\w\s-]/g, '-');
    url = url.replace(/[\s-]+/g, '-');
    return url;
}

function updateAppIds(objects) {
    objects.forEach(obj => {
        const urlFromName = createUrlFromName(obj.name);
        const sport = 'football';
        obj.appId = urlFromName;
        obj.sport = sport;
        obj.reports = obj.reports.map(item => ({...item, appId: urlFromName, sport: sport}));
    });
}

updateAppIds(footballData);
export {footballData};