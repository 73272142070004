import React, {useState} from 'react';
import {Button} from '../Button';
import {NavLink} from 'react-router-dom';
import '../styles/Navbar.css';
import {AuthenticatedTemplate, useMsal, useIsAuthenticated} from "@azure/msal-react";
import {loginRequest, emailData} from "../../Config";
import Dropdown from './Dropdown';
import Sidebar from "../NavbarMobile/NavbarMobile";
import logo from './trackman-logo.png';

function useWindowSize() {

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    React.useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}


function Navbar() {
    const [click, setClick] = useState(false);
    const [dropdownInternal, setDropdownInternal] = useState(false);
    const [dropdownCustomer, setDropdownCustomer] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const onMouseEnterInternal = () => {
        if (window.innerWidth < 1024) {
            setDropdownInternal(false);
        } else {
            setDropdownInternal(true);
        }
    };

    const onMouseLeaveInternal = () => {
        if (window.innerWidth < 1024) {
            setDropdownInternal(false);
        } else {
            setDropdownInternal(false);
        }
    };

    const onMouseEnterCustomer = () => {
        if (window.innerWidth < 1024) {
            setDropdownCustomer(false);
        } else {
            setDropdownCustomer(true);
        }
    };

    const onMouseLeaveCustomer = () => {
        if (window.innerWidth < 1024) {
            setDropdownCustomer(false);
        } else {
            setDropdownCustomer(false);
        }
    };

    const {instance, accounts} = useMsal();

    const extendInternalElement = () => {
        dropdownInternal ? setDropdownInternal(false) : setDropdownInternal(true);
    }

    const extendCustomerElement = () => {
        dropdownCustomer ? setDropdownCustomer(false) : setDropdownCustomer(true);
    }

    const handleEmailClick = () => {
        window.location.href = `mailto:${encodeURIComponent(emailData.emailTo)}?subject=${encodeURIComponent(emailData.emailSubject)}`;
    };

    React.useEffect(() => {

            !isAuthenticated && instance.loginRedirect(loginRequest)
                .catch((error) => console.log(error))

            if (accounts.length > 0) {
                // Get the active account
                const activeAccount = accounts[0];

                // Get username and other account information
                const {username, name} = activeAccount;
                const [firstName] = name.split(' ');
                localStorage.setItem("username", JSON.stringify(username));
                localStorage.setItem("firstName", JSON.stringify(firstName));
            }

        }, [isAuthenticated]
    )

    const size = useWindowSize();
    let isMobile = (size.width < 1025);

    return (
        <div>
            {
                isMobile ?
                    (
                        <AuthenticatedTemplate>
                            <Sidebar/>
                        </AuthenticatedTemplate>
                    ) :
                    (
                        <>
                            <AuthenticatedTemplate>
                                <nav className='navbar'>
                                    <NavLink to='/' className='navbar-logo' onClick={closeMobileMenu}>
                                        <div className="logo-container">
                                            <img src={logo} alt="Trackman" className="trackman-logo"/>
                                            <span className="logo-text">Trackman</span>
                                        </div>
                                    </NavLink>
                                    <div className='menu-icon' onClick={handleClick}>
                                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                                    </div>

                                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                                        <li
                                            className='nav-item'
                                            onMouseEnter={onMouseEnterInternal}
                                            onMouseLeave={onMouseLeaveInternal}
                                        >
                                            <div className='nav-links'>
                                                Internal Reports <i className='fas fa-caret-down'/>
                                            </div>
                                            {dropdownInternal && <Dropdown type='internal' onClick={closeMobileMenu}/>}
                                        </li>
                                        <li></li>
                                        <li
                                            className='nav-item'
                                            onMouseEnter={onMouseEnterCustomer}
                                            onMouseLeave={onMouseLeaveCustomer}
                                            onClick={extendCustomerElement}
                                        >
                                            <div
                                                className='nav-links'
                                            >
                                                Customer Reports <i className='fas fa-caret-down'/>
                                            </div>
                                            {dropdownCustomer && <Dropdown type='customer' onClick={closeMobileMenu}/>}
                                        </li>
                                        <li></li>
                                        <li
                                            className='nav-item'
                                            onClick={handleEmailClick}
                                        >
                                            <div
                                                className='nav-links'
                                            >
                                                Contact
                                            </div>
                                        </li>
                                        <Button buttonStyle='btn--outline'
                                                onClick={() => instance.logout()}>Logout</Button>
                                    </ul>
                                </nav>
                            </AuthenticatedTemplate>
                        </>
                    )
            }
        </div>

    );
}

export default Navbar;
